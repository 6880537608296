import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonGroup,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import Header from '../Header';

import api from '../../../services/api';
import apiWhatsapp from '../../../services/api_whatsapp';
import { ContactDTO } from '../../../services/ContactService';

import { useFunnel } from '../../../hooks/FunnelContext';
import { useLoader } from '../../../hooks/LoaderContext';
import { useSession } from '../../../hooks/SessionContext';
import { useResponsible } from '../../../hooks/ResponsibleContext';

import handleFormData from '../../../utils/handleFormData';
import handleFormDataError from '../../../utils/handleFormDataError';
import handleResponseError from '../../../utils/handleResponseError';
import getNegotiationStatus from '../../../utils/getNegotiationStatus';

import Step from '../../../models/Step';
import NegotiationStatus from '../../../models/NegotiationStatus';
import Channel from '../../../models/Channel';
import Product from '../../../models/Product';
import Age from '../../../models/Age';
import Negotiation from '../../../models/Negotiation';
import MailingTarget from '../../../models/MailingTarget';

import Input from '../../../components/Input';
import InputNumber from '../../../components/InputNumber';
import InputPhone from '../../../components/InputPhone';
import SelectForm from '../../../components/SelectForm';
import ConfirmDialog from '../../../components/ConfirmDialog';

import { StepsRow } from '../Header/styles';
import { CustomGrid } from '../styles';
import { CustomButton } from './styles';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mailingTarget?: MailingTarget;
  funnel_id?: string;
}

const CreateClientDialog: React.FC<Props> = ({
  open,
  setOpen,
  mailingTarget,
  funnel_id,
}) => {
  const { funnels, selectedFunnel } = useFunnel();
  const { user } = useSession();
  const { users } = useResponsible();
  const { setLoading } = useLoader();

  const [contact, setContact] = useState<ContactDTO | undefined>();
  const [name, setName] = useState<string | undefined>(
    mailingTarget?.social_name ?? '',
  );
  const [funnelId, setFunnelId] = useState<string>(selectedFunnel?.id ?? '');
  const [stepId, setStepId] = useState<string | undefined>('');
  const [channelId, setChannelId] = useState<string>('');

  const [ages, setAges] = useState<Age[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [openChooseContact, setOpenChooseContact] = useState<boolean>(false);
  const [formData, setFormData] = useState<{}>();
  const [minPhone, setMinPhone] = useState<number>(0);

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const handleCancel = () => {
    setOpenChooseContact(false);
  };

  useEffect(() => {
    if (mailingTarget) {
      const id = channels.filter(
        channel =>
          channel.name.toLowerCase().includes('mailing') ||
          channel.name.toLowerCase().includes('mailling'),
      )?.[0]?.id;

      setChannelId(id);
    }
  }, [mailingTarget, channels]);

  useEffect(() => {
    const filteredSteps =
      funnels.filter(funnel => funnel.id === funnelId)[0].steps ?? [];

    setSteps(filteredSteps);

    if (filteredSteps.length !== 0) setStepId(filteredSteps[0].id);
  }, [funnels, funnelId]);

  useEffect(() => {
    const loadAges = async () => {
      try {
        const { data } = await api.get<Age[]>('ages');

        setAges(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadAges();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const { data } = await api.get<Product[]>('products', {
          params: {
            funnel_id: funnelId,
          },
        });

        setProducts(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadProducts();
  }, [enqueueSnackbar, funnelId]);

  useEffect(() => {
    const loadChannels = async () => {
      try {
        const { data } = await api.get<Channel[]>('channels', {
          params: {
            funnel_id: funnelId,
          },
        });

        setChannels(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadChannels();
  }, [enqueueSnackbar, funnelId]);

  const handleSelectFunnel = useCallback(async event => {
    const funnelId = event?.target?.value || event;

    setFunnelId(funnelId);
  }, []);

  const handleSelectChannel = useCallback(async event => {
    const channelId = event?.target?.value || event;

    setChannelId(channelId);
  }, []);

  const navigateToEditNegotiation = useCallback(
    (negotiation: Negotiation) => {
      const status = getNegotiationStatus(negotiation);

      history.push(`clients/${negotiation.id}/edit`, {
        negotiation,
        steps: selectedFunnel?.steps,
        funnelId: selectedFunnel?.id,
        status,
      });
    },
    [history, selectedFunnel],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const response = await api.post('clients', {
          ...data,
          mailing_target_id: mailingTarget?.id,
        });

        enqueueSnackbar('Cliente cadastrado com sucesso!', {
          variant: 'success',
        });

        const negotiation: Negotiation = {
          ...response.data.client,
        };

        setOpen(false);

        navigateToEditNegotiation(negotiation);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      enqueueSnackbar,
      setOpen,
      navigateToEditNegotiation,
      mailingTarget,
    ],
  );

  const handleOk = () => {
    handleSubmit(formData);
    setOpenChooseContact(false);
  };

  const handleSelectContact = useCallback(
    async (selectedContact: ContactDTO) => {
      try {
        if (selectedContact) {
          setContact(selectedContact);

          if (!name || name.length === 0) {
            setName(selectedContact.name);
          }
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [name, enqueueSnackbar],
  );

  const handleChangeName = useCallback(async event => {
    setName(event.target.value);
  }, []);

  const handleSelectStep = useCallback((step: Step) => {
    setStepId(step?.id);
  }, []);

  const handleChangeStep = useCallback(
    async (step: Step) => {
      try {
        handleSelectStep(step);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, handleSelectStep],
  );

  const validateFormData = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const dataHandled = handleFormData({
          ...data,
          step_id: stepId,
          contact_id: contact?.id,
        });

        if (dataHandled.step_id === null)
          throw new Error('É obrigatório selecionar uma etapa!');

        const schema = Yup.object().shape({
          name: Yup.string()
            .nullable()
            .required('Nome do contato é obrigatório!'),
          client_landline: dataHandled.contact_id
            ? Yup.string()
                .nullable()
                .min(minPhone, 'Preencha todos os dígitos ou deixe em branco')
                .notRequired()
            : Yup.string()
                .nullable()
                .min(minPhone, 'Preencha todos os dígitos')
                .required('Telefone é obrigatório!'),
          expectation_date: Yup.string()
            .nullable()
            .min(8, 'Data de expectativa deve conter 8 dígitos!')
            .notRequired(),
          anniversary_date: Yup.string()
            .nullable()
            .min(8, 'Data de aniversário deve conter 8 dígitos!')
            .notRequired(),
          company_employer_number: Yup.string()
            .nullable()
            .min(14, 'CNPJ deve conter 14 dígitos!')
            .notRequired(),
          client_social_number: Yup.string()
            .nullable()
            .min(11, 'CPF deve conter 11 dígitos!')
            .notRequired(),
          address_zip_code: Yup.string()
            .nullable()
            .min(8, 'CEP deve conter 8 dígitos!')
            .notRequired(),
        });

        await schema.validate(dataHandled, {
          abortEarly: false,
        });

        if (contact) {
          dataHandled.contact = contact;
          handleSubmit(dataHandled);
        } else {
          setFormData(dataHandled);

          setLoading(true);

          const { status } = await apiWhatsapp.get(
            `phones/${dataHandled.client_landline}`,
          );

          if (status === 204) {
            setOpenChooseContact(true);
          } else {
            handleSubmit(dataHandled);
          }
        }
      } catch (err) {
        const message = handleFormDataError(err, formRef);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [handleSubmit, enqueueSnackbar, setLoading, stepId, contact, minPhone],
  );

  const handleChangeClientLandline = useCallback(
    async (phone?: string) => {
      try {
        if (phone) {
          setLoading(true);

          const { data } = await api.get<ContactDTO | undefined>(
            `contacts/${phone}`,
          );

          setContact(data);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle
          id="confirmation-dialog-title"
          style={{
            backgroundColor: '#6fa7ba',
            color: 'white',
          }}
        >
          Novo cliente
        </DialogTitle>
        <DialogContent dividers>
          <Form
            ref={formRef}
            initialData={{
              user_id: user.id,
              client_landline: mailingTarget?.phone,
              client_social_number: mailingTarget?.social_number,
              company_name: mailingTarget?.employer_name,
              company_employer_number: mailingTarget?.employer_number,
              funnel_id,
            }}
            onSubmit={validateFormData}
          >
            <CustomGrid container spacing={2}>
              <CustomGrid item xs={12}>
                <Header
                  negotiation={undefined}
                  contact={contact}
                  steps={[]}
                  status={NegotiationStatus.InProgress}
                  handleSelectContact={handleSelectContact}
                  handleSelectStep={handleSelectStep}
                  handleEnableFields={() => false}
                  setViewingMode={() => false}
                  viewingMode={false}
                  handleCancel={() => setOpen(false)}
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <Input
                  id="name"
                  name="name"
                  label="Cliente"
                  onChange={handleChangeName}
                  value={name}
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <SelectForm
                  name="channel_id"
                  label="Canal de prospecção"
                  onChange={handleSelectChannel}
                  value={channelId ?? ''}
                  defaultValue={channelId ?? ''}
                  values={channels.map(channel => {
                    return {
                      id: channel.id,
                      name: channel.name,
                    };
                  })}
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <InputPhone
                  id="client_landline"
                  name="client_landline"
                  label="Telefone"
                  disabled={!!contact?.phone}
                  defaultValue={contact?.phone}
                  length={length => setMinPhone(length)}
                  phone={phone => handleChangeClientLandline(phone)}
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <SelectForm
                  name="user_id"
                  label="Responsável"
                  values={users
                    .filter(user => user.active)
                    .map(user => ({
                      id: user.id,
                      name: user.name,
                    }))}
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <Input id="company_name" name="company_name" label="Empresa" />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <SelectForm
                  name="funnel_id"
                  label="Funil"
                  onChange={handleSelectFunnel}
                  value={funnelId}
                  values={funnels.map(funnel => {
                    return {
                      id: funnel.id,
                      name: funnel.name,
                    };
                  })}
                />
              </CustomGrid>
              <CustomGrid item xs={4}>
                <SelectForm
                  name="product_id"
                  label="Produto em negociação"
                  values={products.map(product => {
                    return {
                      id: product.id,
                      name: product.name,
                    };
                  })}
                />
              </CustomGrid>
              <CustomGrid item xs={2}>
                <InputNumber
                  id="value"
                  name="value"
                  label="Valor previsto"
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                />
              </CustomGrid>
              <CustomGrid item xs={6}>
                <StepsRow>
                  <ButtonGroup fullWidth size="small" color="primary">
                    {steps.map(step => (
                      <CustomButton
                        key={step.id}
                        variant={stepId === step.id ? 'contained' : 'outlined'}
                        onClick={() => handleChangeStep(step)}
                      >
                        {step.name}
                      </CustomButton>
                    ))}
                  </ButtonGroup>
                </StepsRow>
              </CustomGrid>
              <CustomGrid item xs={12}>
                Faixa etária dos participantes do contrato:
              </CustomGrid>
              <Scope path="ages">
                {ages.map(age => (
                  <CustomGrid key={age.id} item xs={1}>
                    <Input id={age.id} name={age.id} label={age.name} />
                  </CustomGrid>
                ))}
              </Scope>
            </CustomGrid>
          </Form>
        </DialogContent>
        <DialogActions />
      </Dialog>
      <ConfirmDialog
        open={openChooseContact}
        title="Contato não vinculado"
        message="Contato de Whatsapp não foi vinculado! Deseja salvar assim mesmo?"
        handleSubmit={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default CreateClientDialog;
